import { User } from '../shared/user';

const relation = (a,b,attr,order) => {
    let test;
    if (order=="up") test = ($(a).find('a').data(attr) > $(b).find('a').data(attr));
    else test = $(a).find('a').data(attr) < $(b).find('a').data(attr);
    return 2*Number(test==true) - 1;
}
const sort_projects = (by,order) => {
    console.log("sort projects", by, order);
    let sorted;
    switch (by){
        case 'name':
            sorted = $(".private li").sort( (a,b) => relation(a,b,'name',order) );
        break;
        case 'lastedit':
            sorted = $(".private li").sort( (a,b) => relation(a,b,'updatedAt',order) );
        break;
        case 'size':
            sorted = $(".private li").sort( (a,b) => relation(a,b,'size',order) );
        break;
    }
    $(".private li").detach();
    $(".private ul").append(sorted);
}


$(document).on('turbolinks:load', ()=>{
    console.log("User:", $("#user").data());
    User.onlogin( ()=> { console.log("reloading"); window.location.reload() } );
    User.onlogout( ()=> { console.log("logout"); window.location.reload() } );

    $(".sorts span").click( function(){
        if ($(this).hasClass("down")) $(this).removeClass("down").addClass("up");
        else if ($(this).hasClass("up")) $(this).removeClass("up").addClass("down");
        else {
            $(".sorts .up, .sorts .down").removeClass("up").removeClass("down");
            $(this).addClass("down");
        }
        sort_projects(...this.className.split(" "));
    } );
});
