import { Uploader } from '../shared/uploader';
import { Modular } from '../shared/modular_popin';
import { Message } from '../shared/modular_messages';
import { User } from '../shared/user';

let toolbar, currentExp;


let tryToRename = element => {
    let newname = element.html(), oldname = element.data().name;
    element.removeAttr('contenteditable');
    if (newname == oldname)
        return;
    else if ($(`.projects.private li a[data-name='${newname}']`).length){
        Message.error(`There already is another experiment named <strong>${newname}</strong>`);
        element.html(oldname);
    }
    else
        Uploader.request_json({rename: newname}, '/experiments/'+element.data().slug)
        .success( m => {
            element.html(m.newname);
            element.data('name', m.newname);
            Message.log(`Experiment ${oldname} successfully renamed <strong>${m.newname}</strong>` , 'experimentStatus' );
        })
        .error( m => {
            Message.log(`There was a problem renaming the experiment: ${m.error||m.message}`) 
            element.html(oldname);
        });
    return false;
};


let editExpName = element => {
    toolbar.css('display','none');
    element.attr("contenteditable", true);
    element.focus();
};


const tryExp = experiment => {
    const link = document.createElement('a');
    link.setAttribute('href', `/r/${experiment.slug}/experiment.html?test=true`);
    link.setAttribute('target', '_blank');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

let duplicateExp = experiment => {
    toolbar.css('display','none');
    let link = `/experiments/new?from=${experiment.slug}&name=${experiment.name}_copy`;
    window.location.replace( link );
};


let deleteExp = experiment => {
    toolbar.css('display','none');
    let element = $("#modulars #confirmationdelete"), slug = experiment.slug, name = experiment.name;
    element.find(".warning p").html( `Are you sure you want to delete the experiment named <strong>${name}</strong>?` );
    element.find("button[name='yes']").click( e=> {
        Modular.close();
        Uploader.request_json( {} , '/experiments/'+slug , 'DELETE' )
        .then( m => {
            $(`.projects.private li[slug='${slug}']`).remove();
            if (currentExp.data().slug == slug)
                toolbar.css("display","none"); 
            Message.log( `Experiment <strong>${name}</strong> has been successfully deleted` );            
            if (m.user) {
                let xpsize = experiment.size, usersize = $("#user").data().cached_size;
                m.user = JSON.parse( m.user );
                $("#user").data( m.user );
                console.log("m.user.cached_size", m.user.cached_size, "usersize", usersize, "xpsize", xpsize);
                if (m.user.cached_size != usersize-xpsize)
                    Message.warning( "Some resources might have been transfered to another experiment, refresh this page for an accurate estimation of the size of the other experiments" );
            }
            User.init( $("#user").data() );
        } );
    });
    element.find("button[name='no']").click( () => Modular.close() );
    Modular.show( 'confirmationdelete' );
};


$(document).on('turbolinks:load', ()=>{
    toolbar = $("#toolbar-experiment");
    let ontoolbar = false;
    let width = toolbar.width();
    toolbar.css('display', 'none');

    $(document).on('mouseenter', '.projects.private li', function(){
        const link = $(this).find('a');
        if (link.length && link.attr("contenteditable")) return;
        let top = $(this).offset().top, 
            left = link.offset().left,
            linkWidth = link.width(),
            xoverflow = width+left-$('body').width();
        toolbar.css({
            // top: `calc(${offset.top}px + 0.75em`,
            top: `calc(${top}px + 0.5em)`,
            // left: offset.left - (xoverflow>0?10+xoverflow:0),
            left: left + linkWidth + 16,
            // right: `calc(${offset.left + $(this).width()}px)`,
            'margin-left': '0.5em',
            transform: 'translate(0%, -100%)',
            display: 'flex'
        });
        currentExp = $(this).find('a');
    })

    $(document).on('mouseleave', '.projects, .projects.private ul', function(){
        if (!ontoolbar)
            toolbar.css('display','none');
    });

    $(document).on('mouseenter', '#toolbar-experiment', ()=>{ontoolbar=true; toolbar.css('display','flex')})
               .on('mouseleave', '#toolbar-experiment', ()=>ontoolbar=false);

    toolbar.find(".tryit").on('click', e => tryExp(currentExp.data()) );
    toolbar.find(".duplicate").on('click', e => duplicateExp(currentExp.data()) );
    toolbar.find(".delete").on('click', e=> deleteExp( currentExp.data() ) );
    toolbar.find(".rename").on('click', e=> editExpName( currentExp ) );
    $(".projects.private li a").on('keydown', function(e) {
        e.stopPropagation();
        if (e.key == "Enter"){
            e.preventDefault();
            tryToRename($(this));
        }
        else if (e.key=="Escape")
            $(this).blur();
        console.log("key",e.key);
    }).on('blur', function(e){ $(this).removeAttr("contenteditable"); $(this).html($(this).data().name); })
    .on('click', function(){ return $(this).attr("contenteditable")===undefined; });
});
